<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Add Category </strong> <small>Form</small>
          </div>
         <b-form v-on:submit.prevent.self="submit">

          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="category.CategoryTitle" id="title" placeholder="Category Name"></b-form-input>
              </b-form-group>
            </b-col>
           
            <b-col sm="6">
              <b-form-group>
                <label for="description">Description</label>
                <b-form-input type="text" v-model="category.CategoryDesc" id="description" placeholder="Description"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
         
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="category.CategoryIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />                  
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
              <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>

              <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
              </b-col>
          </b-row>
         </b-form>
        </b-card>

        <b-card>
        <b-card-header>
          <i class="icon-menu mr-1"></i>List of Show Categories
          <div class="card-header-actions">
            
          </div>
        </b-card-header>
        <b-card-body>
          <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
            <div slot="CategoryIsActive" slot-scope="props">
              <c-switch class="mx-1" color="primary" v-model="props.row.CategoryIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.CategoryID)"/>
            </div>
          <div slot="CategoryID" slot-scope="props">
              <i class="fa fa-edit" @click="editCategory(props.row.CategoryID)"></i>
            </div>
          </v-client-table>
        </b-card-body>
      </b-card>
      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
export default {
  name: "Category",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      category: {
        Flag: 1,
        CategoryID: null,
        CategoryTitle: "",
        CategoryDesc: "",
        CategoryIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["CategoryTitle", "CategoryIsActive", "CategoryID"],
      data: [],
      options: {
        headings: {
          CategoryTitle: "Category Name",
          CategoryIsActive: "Status",
          CategoryID: "Category ID"
        },
        sortable: ["CategoryTitle"],
        filterable: ["CategoryTitle"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    resetForm: function() {
      this.commontext.submitButton = "Submit";
      this.category = {
        CategoryID: null,
        Flag: 1,
        CategoryTitle: "",
        CategoryDesc: "",
        CategoryIsActive: true
      };
    },
    getCategories: function() {
      // let res = await MasterService.getCategories();
      // this.data = res.data;
      this.data = [];
      MasterService.getCategories()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CategoryList.length > 0) {
            this.data = data.CategoryList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.category.CategoryTitle == "") {
        this.showMessageSnackbar("Category Title Cannot be empty.");
        return false;
      } else if (this.category.CategoryDesc == "") {
        this.showMessageSnackbar("Category Description Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addCategory(this.category)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.getCategories();
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCategory: function(CategoryID) {
      let payload = {
        CategoryID
      };
      MasterService.getCategoryByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CategoryList.length > 0) {
            this.category = data.CategoryList[0];
            this.commontext.submitButton = "Update Country";

            this.category.Flag = 2;
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "ShowCategory");
      let payload = {
        TableName: "ShowCategory",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
